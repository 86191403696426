@import './assets/icomoon/style.css';
@import 'normalize';
@import 'mixins';
@import 'ngx-toastr/toastr';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Press+Start+2P&display=swap');

* {
  box-sizing: border-box;
  outline: none;
}

body,
html {
  min-width: 100%;
  min-height: 100dvh;
}

body {
  width: 100%;
  display: block;
  background-color: light-dark(var(--color-bg-light), var(--color-bg));
  color: light-dark(var(--color-gray-600), var(--color-text));
  line-height: 1;
  font-family: 'Roboto', sans-serif;
}

:root {
  color-scheme: light dark;

  --color-btn: #692ad1;
  --color-btn-light: #4818f1;
  --color-btn-hover: #2820ac;
  --color-text: #fff;
  --color-text-dark: #5e636d;
  --color-light: #343434;
  --color-bg: #09090a;
  --color-bg-light: #f0f3f5;
  --color-bg-secondary: #151517;
  --color-bg-secondary-light: #e5e8eb;
  --color-input-border: #2f2f37;
  --color-input-border-light: #dee3e7;
  --color-input-error: #821718;
  --color-link: #1a79e8;
  --color-link-light: #4818f1;
  --color-status-success: #00b347;
  --color-status-error: #ee1c1c;
  --color-status-warning: #e9ae16;
  --color-gray-600: #1c1d21;
  --color-gray-600: #17181c;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: light-dark(var(--color-gray-600), var(--color-text)) !important;
  -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #313439;
  box-shadow: none;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

img {
  max-width: 100%;
  height: auto;
}

iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
}

a {
  color: light-dark(var(--color-link-light), var(--color-link));
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.container {
  width: 100%;
  max-width: 1168px;
  min-height: 100%;
  margin: 0 auto;

  @media (min-width: 769px) {
    padding: 60px 20px;
  }
  @media (max-width: 768px) {
    padding: 30px 20px;
  }

  @media (min-width: 1300px) {
    width: 80%;
  }
}

:focus,
:active {
  outline: none;
}

.button {
  width: 100%;
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  color: var(--color-text);
  border: 1px solid light-dark(var(--color-btn-light), var(--color-btn));
  background: light-dark(var(--color-btn-light), var(--color-btn));
  border-radius: 5px;
  transition: background-color 0.5s;
  cursor: pointer;

  &:hover {
    border: 1px solid var(--color-btn-hover);
    background: var(--color-btn-hover);
  }

  &.cancel {
    border: 1px solid light-dark(#cfd6dd, #242428);
    background: light-dark(#cfd6dd, transparent);
    color: inherit;

    &:hover {
      background: light-dark(#dee3e7, #2f2f37);
    }
  }

  &[disabled] {
    opacity: light-dark(1, 0.5);
    pointer-events: none;
    user-select: none;
    cursor: default;
    background: light-dark(#dee3e7, var(--color-btn));
    border-color: light-dark(#dee3e7, var(--color-btn));
    color: light-dark(#8f95a8, var(--color-text));
  }
}

.toast-container {
  width: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  row-gap: 10px;
  right: 24px;

  .ngx-toastr {
    min-height: 80px;
    padding: 20px 20px 20px 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    box-shadow: none;
    background: light-dark(var(--color-bg-secondary-light), var(--color-bg-secondary));
    color: light-dark(var(--color-gray-600), var(--color-text));

    @media (min-width: 769px) {
      width: 420px;
    }

    @media (max-width: 768px) {
      width: 320px;
    }

    &:hover {
      box-shadow: none;
    }

    &::before {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'icomoon';
      font-size: 14px;
      border-radius: 50%;
    }

    &.toast-success {
      &:before {
        content: '\e903';
        background: var(--color-status-success);
      }
    }

    &.toast-warning {
      &:before {
        content: '\e900';
        background: var(--color-status-warning);
      }
    }

    &.toast-error {
      &:before {
        content: '\e904';
        background: var(--color-status-error);
      }
    }

    &.toast-info {
      &:before {
        content: '\e906';
        background: light-dark(var(--color-link-light), var(--color-link));
      }
    }

    .toast-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }

    .toast-message {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      word-break: break-word;

      a {
        color: light-dark(var(--color-link-light), var(--color-link));

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .toast-close-button {
      position: absolute;
      right: 10px;
      top: 0;
      font-size: 25px;
      font-weight: 300;
      text-shadow: none;
      transition: all 0.25s;
      color: light-dark(var(--color-gray-600), var(--color-text));

      &:focus,
      &:hover {
        color: light-dark(var(--color-link-light), var(--color-link));
        opacity: 1;
      }
    }
  }
}

.cdk-overlay-container {
  .cdk-overlay-backdrop {
    background: rgba(0, 0, 0, 0.5);

    &.dialog-overlay-pane-dark {
      background: rgba(0, 0, 0, 0.9);
    }
  }

  .dialog-overlay-pane {
    overflow-y: auto;
    padding: 0;
    width: 408px;
    border-radius: 10px;

    @media (min-width: 769px) {
      max-width: 408px !important;
    }
    @media (max-width: 768px) {
      max-width: 335px !important;
    }

    .mat-mdc-dialog-container {
      position: relative;
      border-radius: 10px;

      .mdc-dialog__surface {
        padding: 0;
        overflow: hidden;
        border-radius: 10px;
        border: 1px solid light-dark(#dee3e7, #2c2c33);
        background: light-dark(#f0f3f5, #161618);
      }

      .close-icon {
        @include close-icon;
      }
    }

    .header {
      min-height: 50px;
      padding: 14px 20px;
      display: flex;
      flex-flow: column;
      border-bottom: 1px solid light-dark(#dee3e7, #2c2c33);

      h2 {
        margin: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .modal-content {
      padding: 10px 24px 20px 24px;

      &__buttons-list {
        margin-top: 10px;
        display: flex;

        button {
          width: 50%;

          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .password-dialog-overlay {
    @media (max-width: 499px) {
      max-width: 90vw !important;
    }

    .mdc-dialog__surface {
      background-color: #1b1b22;
      border-radius: 20px;
    }
  }
}
